.container-aboutMyself{
    padding: 20px;
    margin-left: 100px;
    margin-right: 100px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    overflow-x: auto;
}
.container {
  display: flex;
  justify-content: center;
  padding: 20px;
  max-width: 100%;
  box-sizing: border-box;
  
}
.intro {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -160px;
  margin-top: -80px;
}

.containerProfil {
  padding: 215px;
}
.circleImage {
  width: 480px;
  height: 480px;
  border-radius: 60%;
  max-width: none;
}

@media (max-width: 1210px) {
  
  .container-aboutMyself{
    margin: 5px;
      padding: 60px;
      background-color: #f9f9f9;
      border-radius: 10px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      overflow-x: auto;
      margin-right: 5px;
      margin-left: 5px;
      
  }
  .intro {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: -160px;
    margin-top: -45px;
  }
}

@media (max-width: 968px) {
  .circleImage {
    width: 0px;
    height: 0px;
    margin: 0 auto 10px;
  }
  .container-aboutMyself{
    margin: 5px;
      padding: 60px;
      background-color: #f9f9f9;
      border-radius: 10px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      overflow-x: auto;
      margin-right: 5px;
      margin-left: 5px;
      margin-top: 50px;
  }
  .titre1 {
    font-size: 90px;
    font-weight: bold;
    margin: 0 0 40px 0;
    line-height: 100px;
    font-family: Arial, Helvetica, sans-serif;
    
  }
}

.paragraph {
  font-size: 15px;
  line-height: 30px;
  margin-bottom: 10px;
  font-family: 'Lucida Sans';
  margin-left: -180px;
  margin-top: 25px;
}

.titre1 {
  font-size: 90px;
  font-weight: bold;
  margin: 0 0 40px 0;
  line-height: 100px;
  font-family: Arial, Helvetica, sans-serif;
}

.titre2 {
  font-size: 22px;
  font-weight: bold;
  font-family: 'serif';
  margin: 0 0 40px 0;
  margin-bottom: 20px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  line-height: 5px;

}
.texte{
    line-height: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.innerContainer {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 50px;
}

.circleButton {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  margin-right: 15px;
  transition: background-color 2s ease;
}
.circleButton:hover{
  background-color: rgb(252, 252, 252);
  transform: scale(1.1);
}

.cv {
  background-color: #f39624;
}

.projets {
  background-color: #d72f2f;
}

.contacts {
  background-color: #1d64c1;
}
