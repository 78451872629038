/* General container styling */
.lebenslauf-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    overflow-x: auto;
    margin-right: 100px;
    margin-left: 100px;
    margin-bottom: 20px;
    align-items: center;
}

.lebenslauf-container h1 {
    margin-left: 5%;
    margin-bottom: 5%;
    font-size: 38px;
}
.lebenslauf-container h3{
    color: gray;
    text-align: center;
    margin-left: 10%;

}
.arbeit{
    margin-top: 50px;
    flex: 1;
    display: flex;
    align-items: flex-start;
    gap: 20px;
    justify-items: center;
    border-bottom: 3px solid grey;
    flex-wrap: wrap;
    overflow: hidden;
    margin-left: 30%;
}
.jahre{
    margin-left: 30px;
    min-height: 20px;
    min-width: 170px;
}

.arbeit p {
    font-family: 'Courier New', Courier, monospace;
    color:rgb(69, 68, 68);
    font-weight: bold;
    margin-bottom: -10px;
}
.beschreibung{
    flex: 1;
    margin-inline-start: 50px;
}

.stelle ul{
    overflow: hidden;
}

.stelle ul li{
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    word-spacing: 2px;
    margin-bottom: 10px;
}

.stelle p{
    font-weight: bold;
    font-size: 17px;
}
.progress{
    min-height: 10%;
    min-width: 100%;
   
}
.progress label {
    margin-right: 40px;
    margin-left: 42%;  
}

/* Responsive styling */
@media (max-width: 868px) {
  
    .lebenslauf-container {
        font-family: Arial, sans-serif;
        margin: 40px;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        margin-right: 5px;
        margin-left: 5px;
    }
  
    .jahre{
        margin-left: 30px;
        min-height: 20px;
        min-width: 200px;
    }
    .stelle{
        word-wrap: break-word; 
    }
}

@media (max-width: 1068px) {
  
    .lebenslauf-container {
        font-family: Arial, sans-serif;
        margin: 40px;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        margin-right: 5px;
        margin-left: 5px;
    }
    .arbeit{
        margin-left: -10px;
    }
    .progress{
        min-height: 10%;
        min-width: 100%;
        text-align: center;
    }
    .progress label {
        margin-right: 2opx;
        margin-left: 2%;  
    }
}
