.container-my-projets{
    font-family: Arial, sans-serif;
    margin-left: 100px;
    margin-right: 100px;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    overflow-x: auto;
}
.texteProjets{
    font-size: 25px;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}
.projet-title{
    flex: 1;
    margin-left: 1vw;
    font-size: 20px;
    padding: 10px;
}

.bloc-projet{
    flex: 1;
    padding: 15px;
    margin-left: 80px;
    max-width: 450px; /* Largeur maximale */
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;    
}
.bloc-projets{
    display: flex;
    margin-right: 20px;
    margin-top: 110px;
    justify-content: center;
    align-items: center;
}
.solarEnergyImage{
    width: 450px;
    height: 280px;
    border-radius:10px;
    margin-left: 60px;
}
.bloc-projets p {
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    margin-top: 20px;
    word-spacing: 5px;
}
.texte-projet{
    font-family: 'Courier New', Courier, monospace;
}
.projet-inhalt{
    align-items: flex-start;
    position: relative;
}

@media (max-width: 1068px) {
    .container-my-projets{
        font-family: Arial, sans-serif;
        margin: 10px;
        padding: 10px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        overflow-x: auto;
        margin-left: 5px;
        margin-right: 5px;
    }
    .projet-title{
        flex: 1;
        font-size: 20px;
        padding: 15px;
        margin-top: -20px;
        margin-left: 30px;
        align-items: flex-start;

    }
    .bloc-projets{
        display: flex;
        margin-right: 20px;
        margin-top: 30px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        align-items: center;
    }
    .projet-inhalt{
        align-items: center;
    }
 
}
.espace-bloc li{
    margin-bottom: 8px;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    word-spacing: 5px;
}

@media (max-width: 468px) {
    .container-my-projets{
        font-family: Arial, sans-serif;
        margin: 10px;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        overflow-x: auto;
        margin-left: 5px;
        margin-right: 5px;
       
    }
    .projet-title{
        font-size: 20px;
        padding: 15px;
        margin-top: -20px;
        margin-left: 30px;
        margin-bottom: -30px;
    }
    .bloc-projets{
        display: flex;
        margin-right: 20px;
        margin-top: 30px;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    }
    .solarEnergyImage{
        max-width: 90%; /* L'image occupe toute la largeur disponible */
        height: auto; /* Conserve les proportions de l'image */
        align-items: flex-start;
        justify-content: flex-start;
        margin-right: 50px;
        margin-left: 25px;
    }
    .bloc-projet{
        padding: 10px;
        margin-left: 20px;
        max-width: 450px; /* Largeur maximale */
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: normal;
        align-items: flex-start; 
    }
      
}