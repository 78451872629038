.contact-container{
    padding: 20px;
    margin-left: 100px;
    margin-right: 100px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(62, 36, 36, 0.1);
    box-sizing: border-box;
    overflow-x: auto;
    display: flex;
    align-items: center;
}

.input-first-containers{
    display: flex;
    flex-direction: column;
}

.input-second-containers{
    display: flex;
    flex-direction: column;
}

.input-third-containers{
    display: flex;
    flex-direction: column;
}

.input-first-containers label {
    font-size: 14px; 
    margin-bottom: 8px; 
    color: #333; 
}

.input-second-containers label {
    font-size: 14px; 
    margin-bottom: 8px; 
    color: #333; 
}
.input-third-containers label {
    font-size: 14px; 
    margin-bottom: 8px; 
    color: #333; 
}

.input-first-containers input {
    padding: 10px; 
    font-size: 16px; 
    border: 2px solid #ccc; 
    border-radius: 2px; 
    box-sizing: border-box; 
    outline:#333;
  }

  .input-second-containers input {
    padding: 10px; 
    font-size: 16px; 
    border: 2px solid #ccc; 
    border-radius: 2px; 
    box-sizing: border-box; 
    outline:chocolate;
  }
  .input-third-containers textarea {
    padding: 25px; 
    font-size: 16px; 
    width: 290px;
    height: 160px;
    border: 2px solid #ccc; 
    border-radius: 2px; 
    box-sizing: border-box; 
    outline:chocolate;
  }

  .name-container{
    display: flex;
    gap: 15px;
  }
  .name-subject-container{
    display: flex;
    gap: 15px;
  }

  .send-message-container{
    display: flex;
    flex-direction: column;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;    
    gap: 30px;
    margin-left: 200px;

  }
  .name-subject-container-message{
    display: flex;
    align-items: center;
    gap: 15px;
    
  }
  .contact-container{
    justify-content: center;
    align-items: center;
    padding: 100px;
  }
.h1{
    font-size: 17px;
}

.title-contact {
    font-size: 38px;
    margin-bottom: -12px;
}

.title-telefon{
    font-size: 18px;
    margin-bottom: -12px;
}

.title-email{
    font-size: 18px;
    margin-bottom: -12px;
}

.texte-contact{
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
}

.contact{
    margin-top: 25px;
    background-color: #f39624;
    margin-left: 8px;
}

.custom-input {
    font-family: 'Arial', sans-serif; 
    font-size: 1px;                 
    padding: 10px;
    border: 0.5px solid #ccc;
  }
  @media (max-width: 1068px) {
    .contact-container{
      padding: 60px;
      margin-left: 5px;
      margin-right: 5px;
      background-color: #f9f9f9;
      border-radius: 10px;
      box-shadow: 0px 4px 8px rgba(62, 36, 36, 0.1);
      box-sizing: border-box;
      overflow-x: auto;
      display: flex;
      align-items: center;
      margin-top: 50px;
    }
  }
  
  @media (max-width: 868px) {
    .contact-container{
      padding: 20px;
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: 20px;
      background-color: #f9f9f9;
      border-radius: 10px;
      box-shadow: 0px 4px 8px rgba(62, 36, 36, 0.1);
      box-sizing: border-box;
      overflow-x: auto;
      display: flex;
      align-items: center;
    }
    .contact-details{
      margin-right: 35px;
      align-items: flex-start;
      margin-bottom: 80px;
      justify-content: center;
      align-items: center;
    }
    .contact-container{
      padding: 40px;
      flex-direction: column;
      margin-bottom: 50px;
      
    }
  }

  @media (max-width: 568px) {
    .contact-details{
      margin-left: auto;
      margin-right: auto;
      align-items: center;
      margin-bottom: 70px;
    }
    .contact-container{
      padding: 40px;
      flex-direction: column;
      margin-bottom: 50px;
    }
    .name-container,
    .name-subject-container,
    .name-subject-container-message {
        flex-direction: column;
        display: flex;
        gap: 30px;
        margin-right: 170px;
        align-items: center;
        justify-content: center;
    }
  
    .contact-container{
      margin: 20px;
      padding: 20px;
      background-color: #f9f9f9;
      border-radius: 10px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      overflow-x: hidden;
      align-items: center;
      justify-content: center;
  }
}