.container {
    flex-direction: row;  
  }
  
  .circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: orange;
    border: none;
    margin-right: 10px;
    cursor: pointer;
  }

  .circle:hover {
    transform: scale(1.1); /* Légère animation au survol */
  }

  .boldText {
    font-size: 20px;
    font-weight: bold;
    flex: 1;
    color: rgb(46, 41, 41);
  }
  
  .iconButton {
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 1px;
  }
  
  @media (max-width: 468px) {
    .circle {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: orange;
      border: none;
      margin-right: 5px;
      cursor: pointer;
      margin-left: -20px;
    }
    .container {
      flex-direction: row; 
      margin-top: -10px;
    }
    .iconButton {
      background: none;
      border: none;
      cursor: pointer;
      margin-right: -20px;
    }
  }