.footer-container {
  padding: 30px;
  background-color: #f8f9fa;
  border-top: 1px solid #e7e7e7;
  text-align: center;
  
}

.footer-title {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: bold;
}

.footer-info-container {
  margin: 5px 0;
  font-family: 'Courier New', Courier, monospace;
}

.footer-label {
  font-weight: bold;
  margin-right: 5px;
}

.footer-text {
  display: inline-block;
}

.footer-links-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.footer-link-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.footer-link-button:hover {
  background-color: #423d37;
}

.footer-copyright {
  margin-top: 15px;
  font-size: 14px;
  color: #6c757d;
  font-family: 'Courier New', Courier, monospace;
}
